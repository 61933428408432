<!-- 人员详情 -->
<template>
  <div class="content">
    <div class="nav">
      <div @click="goback">&lt;</div>
      <div style="color: #c8c8c8" @click="goback">
        考试管理/层级一：{{ des.titlelevelName }}
      </div>
      <div @click="goback">/人员详情</div>
    </div>
    <div style="display: flex; align-items: center">
      <div class="line"></div>
      <div style="font-size: 20px; font-weight: bold">学员信息</div>
    </div>
    <div class="information">
      <div>姓名：{{ des.name }}</div>
      <div>等级：{{ name }}</div>
      <div>地区:{{ des.provinceName }}{{ des.cityName }}</div>
      <div>门店：{{ des.storeName }}</div>
    </div>
    <div style="display: flex; align-items: center">
      <div class="line"></div>
      <div style="font-size: 20px; font-weight: bold">答题情况</div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        margin: 30px 0 30px 67px;
        font-size: 20px;
      "
    >
      <div>试卷答题情况：</div>
      <div style="color: #409eff; margin: 0 15px">{{ userScore || 0}}分</div>
      <el-button type="primary" class="btn" @click="details"
        >查看详情</el-button
      >
    </div>
    <div class="content2">
      <div>
        <div style="display: flex; align-items: flex-end">
          <div style="font-size: 20px">图片资料:</div>
          <div style="font-size: 14px; color: #c8c8c8">(点击查看大图)</div>
        </div>
        <el-carousel
          indicator-position="outside"
          :autoplay="false"
          trigger="click"
          style="width: 515px; border-radius: 14px; margin: 15px 0"
        >
          <el-carousel-item
            v-for="item in picList"
            :key="item.id"
            style="border-radius: 14px"
          >
            <img
              :src="item.resourceUri"
              alt=""
              style="
                width: 515px;
                height: 307px;
                border-radius: 14px;
                margin: 15px 0;
              "
            />
          </el-carousel-item>
        </el-carousel>

        <!-- <div style="font-size: 20px">图片介绍:xxx</div> -->
      </div>
      <div>
        <div style="font-size: 20px">视频资料:</div>
        <el-carousel
          indicator-position="outside"
          :autoplay="false"
          trigger="click"
          style="width: 515px; border-radius: 14px; margin: 15px 0"
        >
          <el-carousel-item
            v-for="item in videoList"
            :key="item.id"
            style="border-radius: 14px"
          >
            <video
              :src="item.resourceUri"
              controls
              alt=""
              style="
                width: 515px;
                height: 307px;
                border-radius: 14px;
                margin: 15px 0;
              "
            ></video>
          </el-carousel-item>
        </el-carousel>
        <!-- <div style="font-size: 20px">图片介绍:xxx</div> -->
      </div>
    </div>
    <!-- <div
      style="
        display: flex;
        justify-content: space-around;
        margin: 30px 57px 60px;
        padding-bottom: 60px;
      "
    >
      <div style="display: flex">
        <div class="buttomlr">&lt;</div>
        <div style="line-height: 34px; margin-left: 25px">1</div>
        <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
          /5
        </div>
        <div class="buttomlr">&gt;</div>
      </div>
      <div style="display: flex">
        <div class="buttomlr">&lt;</div>
        <div style="line-height: 34px; margin-left: 25px">1</div>
        <div style="color: #e4e4e4; line-height: 34px; margin-right: 25px">
          /5
        </div>
        <div class="buttomlr">&gt;</div>
      </div>
    </div> -->
    <img
      v-if="des.ispass==false"
      src="../../images/through.png"
      alt=""
      style="width: 300px; height: 300px; position: absolute; top: 0; right: 0"
    />
    <img
      v-if="des.ispass==true"
      src="../../images/nothrough.png"
      alt=""
      style="width: 300px; height: 300px; position: absolute; top: 0; right: 0"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      width="805px"
      :before-close="handleClose"
    >
      <!--选择题-->
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <div class="line2"></div>
          <div style="color: #409eff; font-size: 20px">
            选择题：（每题{{ arr1[0].score }}分，共{{ arr1.length }}题）
          </div>
        </div>
        <div style="display: flex; align-items: baseline">
          <div style="font-size: 32px; color: #ff1b0b">{{ userScore }}分</div>
          <div style="font-size: 16px; color: #bababa">(满分100分)</div>
        </div>
      </div>
      <div
        style="display: flex; flex-direction: column; margin: 30px 0"
        v-for="(item,index) in arr1"
        :key="item.id"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex">
            <div style="margin-right: 50px">{{index+1}}.{{ item.questionContent }}</div>
            <div>(正确答案：{{ item.question }})</div>
          </div>
          <div>学员填写：{{item.userquestions}}</div>
        </div>
        <div class="selectA">
          <div v-for="item2 in item.questionAnswer" :key="item2">
            {{ item2 }}
          </div>
        </div>
      </div>

      <!--判断题-->
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <div class="line2"></div>
          <div style="color: #409eff; font-size: 20px">
            判断题：（每题{{arr2[0].score}}分，共{{arr2.length}}题）
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0" v-for='(item,index) in arr2' :key='item.id'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex">
            <div style="margin-right: 50px">{{index+1}}.{{item.questionContent}}</div>
            <div style="display:flex;align-items:center" v-if="item.rightKey=='10000000'">(正确答案：<img src="../../images/succ.png" alt="" style="width:22px;height:22px"> )  </div>
            <div style="display:flex;align-items:center" v-if="item.rightKey=='01000000'">(正确答案：<img src="../../images/err.png" alt="" style="width:22px;height:22px"> )  </div>
          </div>
          <div v-if="item.rightKey==item.userRightKey">学员填写：<img src="../../images/selsucc.png" alt="" style="width:22px;height:22px"></div>
          <div v-if="item.rightKey!=item.userRightKey">学员填写：<img src="../../images/selerr.png" alt="" style="width:22px;height:22px"></div>
        </div>
      </div>
     
      <!--填空题-->
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <div class="line2"></div>
          <div style="color: #409eff; font-size: 20px">
            填空：（每题{{arr3[0].score}}分，共{{arr3.length}}题）
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0" v-for='(item,index) in arr3' :key='item.id'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex; flex-direction: column">
            <div style="margin-right: 50px">
             {{index+1}}.{{item.questionContent}}
            </div>
            <div style="margin: 15px 0">正确答案：{{item.questionAnswer}}</div>
            <div>
              学员填写：{{item.userQuestionAnswer}}
            </div>
          </div>
        </div>
      </div>
      
      <!--主观题-->
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <div class="line2"></div>
          <div style="color: #409eff; font-size: 20px">
            主观题：（每题{{arr4[0].score}}分，共{{arr4.length}}题）
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0" v-for='(item,index) in arr4' :key='index'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{index+1}}.{{item.questionContent}}?</div>
            <div style="color: #bababa; margin-left: 39px" @click="lookanswer(index,item)">
              查看答案<img
                v-show="item.checked"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!item.checked"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
          </div>
          <div>{{item.userScore}}分</div>
        </div>
        <div
          style="
            width: 626px;
            max-height: 158px;
            overflow: auto;
            border: 1px solid #cacaca;
            padding: 14px;
            margin: 15px 0;
            font-size: 16px;
            line-height: 34px;
            min-height:158px
          "
        >
         {{item.userQuestionAnswer}}
        </div>
        <div
          style="
            width: 626px;
            max-height: 158px;
            overflow: auto;
            border: 1px solid #cacaca;
            padding: 14px;
            margin: 15px 0;
            font-size: 16px;
            line-height: 34px;
            color: #cacaca;
            min-height:158px
          "
          v-show="item.checked"
        >
         {{item.questionAnswer}}
        </div>
      </div>
      <!--看图说话-->
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; align-items: center">
          <div class="line2"></div>
          <div style="color: #409eff; font-size: 20px">
            看图说话：（每题{{arr5[0].score}}分，共{{arr5.length}}题）
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; margin: 30px 0" v-for='(item,index) in arr5' :key='index'>
        <div
          style="
            display: flex;
            justify-content: space-between;
            font-size: 20px;
            color: #000;
          "
        >
          <div style="display: flex; align-items: center">
            <div>{{index+1}}.{{item.questionContent}}?</div>
            <div style="color: #bababa; margin-left: 39px" @click="lookanswer(index,item)">
              查看答案<img
                v-show="item.checked"
                src="../../images/down.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
              <img
                v-show="!item.checked"
                src="../../images/up.png"
                alt=""
                style="width: 18px; height: 18px; margin-left: 10px"
              />
            </div>
          </div>
          <div>{{item.userScore}}分</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            margin: 15px 0;
            justify-content: space-between;
          "
        >
          <div
            style="
              width: 153px;
              height: 153px;
              
            "
          >
          <el-carousel trigger="click" height="150px">
          <el-carousel-item v-for="item2 in 4" :key="item2">
            <img :src="item.resourceUri" alt="" style="width:100%;height:100%">
          </el-carousel-item>
        </el-carousel>
            <!-- <div
              style="
                width: 100%;
                height: 32px;
                background: #bababa;
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
              "
            >
              <div>&lt;</div>
              <div style="display: flex; margin: 0 23px">
                <div>1</div>
                <div>/5</div>
              </div>
              <div>&gt;</div>
            </div> -->
          </div>
          <div
            style="
              width: 476px;
              max-height: 158px;
             
              overflow: auto;
              border: 1px solid #cacaca;
              font-size: 16px;
              line-height: 34px;
            "
          >
            <div style="margin: 12px;min-height: 158px;">
             {{item.userQuestionAnswer}}
            </div>
          </div>
        </div>

        <div
          style="
            width: 626px;
            max-height:158px;
            min-height:158px;
            overflow: auto;
            border: 1px solid #cacaca;
            padding: 14px;
            margin: 15px 0;
            font-size: 16px;
            line-height: 34px;
            color: #cacaca;
          "
          v-show="item.checked"
        >
         {{item.questionAnswer}}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  levelList,
  listByUserAndLevelId,
  getNewestExamState,
  getAllQuestionAndStatistic,
} from "../../apis/index";
export default {
  data() {
    return {
      arr1: [//选择题
        {
          score: "",
        },
      ], 
      arr2:[//判断题
        {
          score:''
        }
      ],
      arr3:[//填空题
        {
          score:''
        }
      ],
      arr4:[//主观题
        {
          score:''
        }
      ],
      arr5:[//看图说话
        {
          score:''
        }
      ],
      through: true,
      dialogVisible: false,
      answer: false,
      des: {},
      name: "",
      picList: [],
      videoList: [],
      userScore: "",
      paperId: "",
    };
  },
  created() {
    let des = JSON.parse(this.$route.query.row);
    this.des = des;
    console.log(des);
    this.level();
    this.mediaInfo();
    this.score();
  },
  methods: {
    async paper() {
      //获取试卷详情
      const params = {
        paperId: this.paperId,
      };
      const { data } = await getAllQuestionAndStatistic(params);
      if (data.code == 200) {
        let list = data.data;
        let arr1 = data.data[0].questionList; //选择题
        arr1.forEach((item, index) => {
          item.questionAnswer = item.questionAnswer.split("&&&");
          item.rightKey = item.rightKey.split("");
          item.userRightKey = item.userRightKey.split("");
          let arr = item.rightKey.map((item2, index2) => {
            return {
              name: "",
              value: item2,
            };
          });
            let userarr = item.userRightKey.map((item2, index2) => {
            return {
              name: "",
              value: item2,
            };
          });
          arr.forEach((item3, index3) => {
            if (item3.value == 1) {
              if (index3 == 0) {
                item3.name = "A";
              } else if (index3 == 1) {
                item3.name = "B";
              } else if (index3 == 2) {
                item3.name = "C";
              } else if (index3 == 3) {
                item3.name = "D";
              } else if (index3 == 4) {
                item3.name = "E";
              } else if (index3 == 5) {
                item3.name = "F";
              }
            }
          });
           userarr.forEach((item3, index3) => {
            if (item3.value == 1) {
              if (index3 == 0) {
                item3.name = "A";
              } else if (index3 == 1) {
                item3.name = "B";
              } else if (index3 == 2) {
                item3.name = "C";
              } else if (index3 == 3) {
                item3.name = "D";
              } else if (index3 == 4) {
                item3.name = "E";
              } else if (index3 == 5) {
                item3.name = "F";
              }
            }
          });
          let question = arr.filter((item4) => {
            if (item4.name != "") {
              return item4.name;
            }
          });
          let userquestion = userarr.filter((item4) => {
            if (item4.name != "") {
              return item4.name;
            }
          });
          item.rightKey = arr;
          item.userRightKey = userarr;
          let questions = question.map((item5) => {
            return item5.name;
          });
          item.question = questions.join(",");
          let userquestions = userquestion.map((item5) => {
            return item5.name;
          });
          item.userquestions = userquestions.join(",");

          //  console.log(arr)
        });
        this.arr1 = arr1;
        let arr2 = data.data[1].questionList; //判断题
        this.arr2=arr2
        // console.log(arr2)
        let arr3 = data.data[2].questionList; //填空题
        arr3.forEach(item=>{
          item.questionAnswer=item.questionAnswer.replace(/&&&/g,'/')
          item.userQuestionAnswer=item.userQuestionAnswer.replace(/&&&/g,'/')
        })
        this.arr3=arr3
        // console.log(arr3)
        let arr4 = data.data[3].questionList; //主观题
        arr4.forEach(item=>{
          item.checked=false
        })
        this.arr4=arr4
        // console.log(arr4)
        let arr5 = data.data[4].questionList; //看图说话
        arr5.forEach(item=>{
          item.checked=false
        })
        this.arr5=arr5
        console.log(arr5)
      } else {
        this.$message.error(data.msg);
      }
    },
    async score() {
      //获取分数
      const params = {
        userId: this.des.id,
      };
      const { data } = await getNewestExamState(params);
      if (data.code == 200) {
        this.userScore = data.data.userScore;
        this.paperId = data.data.id;
      } else {
        this.$message.error(data.msg);
      }
    },
    async mediaInfo() {
      //获取图片视频信息
      const params = {
        levelId: this.des.levelId,
        userId: this.des.id,
      };
      const { data } = await listByUserAndLevelId(params);
      if (data.code == 200) {
        this.picList = data.data.picList;
        this.videoList = data.data.videoList;
      } else {
        this.$message.error(data.msg);
      }
    },
    async level() {
      //获取等级列表
      const { data } = await levelList({ roleId: this.des.roleId });
      if (data.code == 200) {
        let level = data.data.filter((item) => {
          return item.id == this.des.levelId;
        });
        this.name = level[0].name;
      } else {
        this.$message.error(data.msg);
      }
    },
    goback() {
      this.$router.go(-1);
    },
    lookanswer(index,item) {
      // console.log(item.typeId)
      if(item.typeId==4){//主观题
      let arr=this.arr4
      arr[index].checked=!arr[index].checked
      this.arr4=arr
      }else{//看图说话
      let arr=this.arr5
      arr[index].checked=!arr[index].checked
      this.arr5=arr 
      }
    //  console.log(index,item)
    },
    details() {
      if(this.paperId==''){
        this.$message.error('暂无试卷信息')
        return 
      }
      this.dialogVisible = true;
      this.paper();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-dialog__body {
  //   padding: 30px 40px;
  padding: 30px 70px;
  max-height: 646px;
  overflow: auto;
}
.selectA {
  display: flex;
  color: #bababa;
  margin-top: 15px;
  flex-wrap: wrap;
  div {
    margin: 0 20px;
    font-size: 20px;
  }
}
.selectA > div:first-child {
  margin-left: 0;
}
.line2 {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin-right: 11px;
}
.buttomlr {
  width: 34px;
  height: 34px;
  border: 1px solid #e4e4e4;
  line-height: 34px;
  text-align: center;
  border-radius: 6px;
  color: #e4e4e4;
}
.content2 {
  display: flex;
  margin: 0 57px;
  justify-content: space-between;
}
/deep/.el-button {
  height: 34px;
}
.information {
  display: flex;
  margin: 30px 0 60px 67px;
  width: 70%;
  justify-content: space-between;
}
.line {
  width: 9px;
  height: 22px;
  border-radius: 6px;
  background: #409eff;
  margin: 0 11px 0 67px;
}
.content {
  margin: 13px 0;
  background: #fff;
  position: relative;
  .nav {
    display: flex;
    font-size: 26px;
    font-weight: bold;
    padding: 23px 0;
    div:first-child {
      width: 57px;
      text-align: center;
      color: #c8c8c8;
    }
  }
}
//  .el-carousel__item h3 {
//     color: #475669;
//     font-size: 18px;
//     opacity: 0.75;
//     line-height: 300px;
//     margin: 0;
//   }

// .el-carousel__item:nth-child(2n) {
//   background-color: #99a9bf;
// }

// .el-carousel__item:nth-child(2n+1) {
//   background-color: #d3dce6;
// }
</style>
